import { getCollaborationLinkData } from "./data";
import { importUsernameFromLocalStorage } from "./data/localStorage";

const AudioSdkIframe = () => {
  const roomLinkData = getCollaborationLinkData(window.location.href);
  const username = importUsernameFromLocalStorage();

  if (roomLinkData == null) {
    return null;
  }

  const { roomId } = roomLinkData;

  return (
    <iframe
      title="Audio SDK Iframe"
      src={`https://${
        import.meta.env.VITE_AUDIO_SDK_HOST
      }/landing/audio-sdk-iframe.js?key=${
        import.meta.env.VITE_AUDIO_SDK_KEY
      }&room=${roomId}&iframe=true&username=${username}`}
      allow="microphone"
      allowTransparency={true}
      frameBorder="0"
      className="audio-sdk-iframe"
    ></iframe>
  );
};

export default AudioSdkIframe;
